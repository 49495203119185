<template>
  <div class="OperationPosList-wrapper">
    <list
      ref="list"
      exportPermission="export"
      :searchUrl="searchUrl"
      :exportUrl="exportUrl"
      :searchParams.sync="searchParams"
      :headers="headers"
      :isMultiSelect="true"
    >
      <template #headerSlot>
        <v-button text="新增" permission="add" @click="create"></v-button>
      </template>
      <template #searchSlot>
        <v-input label="标题" v-model="searchParams.title"></v-input>
        <v-select label="发布位置" v-model="searchParams.publishPosition" :options="publishPositionOps"></v-select>
        <v-select label="状态" v-model="searchParams.status" :options="statusOps"></v-select>
        <v-select label="组织置顶" v-model="searchParams.orgSticky" :options="orgStickyOps"></v-select>
        <v-select2 label="发布项目" v-model="searchParams.communityId" v-bind="communityParams"></v-select2>
        <v-select2 label="所属公司" v-model="searchParams.regionId" v-bind="regionParams" :width="160"></v-select2>
        <v-input label="创建人" v-model="searchParams.inuser"></v-input>
        <v-datepicker label="创建时间段" type="rangedatetimer" :startTime.sync="searchParams.intimeStart" :endTime.sync="searchParams.intimeEnd"></v-datepicker>
      </template>
      <template #operateSlot="scope">
        <v-button type="text" permission="update" @click="edit(scope.row)">编辑</v-button>
        <v-button v-if="scope.row.status === 1" type="text" permission="delete" @click="closeStatus(scope.row)">关闭</v-button>
      </template>
      <template #batchSlot="scope">
        <v-button text="批量审核" :disabled="examineDis(scope.selectedData.data, 2)" permission="batchUpdate" @click="batch(scope.selectedData, 1)"></v-button>
        <v-button text="批量关闭" :disabled="examineDis(scope.selectedData.data, 1)" permission="batchDelete" @click="batch(scope.selectedData, 0)"></v-button>
      </template>
    </list>
  </div>
</template>

<script>
import { getListURL, exportListURL, getDropDownSearchTagURL, batchURL } from './api'
import {
  statusMap,
  setStatusOps,
  publishPositionMap,
  setPublishPositionOps,
  setOrgStickyOps
} from './map'
import {
  communityParams, regionParams
} from 'common/select2Params'
export default {
  name: 'OperationPosList',
  data () {
    return {
      searchUrl: getListURL,
      exportUrl: exportListURL,
      statusOps: setStatusOps(1),
      publishPositionOps: setPublishPositionOps(1),
      orgStickyOps: setOrgStickyOps(1),
      communityParams,
      regionParams,
      orgTagParams: {
        searchUrl: getDropDownSearchTagURL,
        request: {
          text: 'tagName',
          value: 'id'
        },
        response: {
          text: 'tagName',
          value: 'id'
        }
      },
      searchParams: {
        title: '',
        publishPosition: undefined,
        status: undefined,
        orgSticky: undefined,
        communityId: '',
        regionId: '',
        inuser: '',
        intimeStart: '',
        intimeEnd: ''
      },
      headers: [
        {
          prop: 'title',
          label: '标题'
        },
        {
          prop: 'communityName',
          label: '发布项目'
        },
        {
          prop: 'regionName',
          label: '所属公司'
        },
        {
          prop: 'onlineFromDate',
          label: '上线时间'
        },
        {
          prop: 'onlineToDate',
          label: '下线时间'
        },
        {
          prop: 'orgTagNames',
          label: '关联组织标签'
        },
        {
          prop: 'sendPosStrText',
          label: '发布位置',
          formatter (row) {
            return publishPositionMap[row.publishPosition]
          }
        },
        {
          prop: 'orgStickyText',
          label: '组织置顶',
          formatter (row) {
            return row.orgSticky ? '置顶' : '不置顶'
          }
        },
        {
          prop: 'sort',
          label: '排序',
          align: 'right'
        },
        {
          prop: 'statusText',
          label: '状态',
          formatter (row) {
            return statusMap[row.status]
          }
        },
        {
          prop: 'readNum',
          label: '阅读数',
          align: 'right'
        },
        {
          prop: 'inuserName',
          label: '创建人'
        },
        {
          prop: 'intime',
          label: '创建时间'
        }
      ]
    }
  },
  computed: {
    examineDis () {
      return (data, status) => {
        let result = false
        data.forEach(item => {
          if (item.status !== status) {
            result = true
          }
        })
        return result || data.length === 0
      }
    }
  },
  methods: {
    updateStatus (ids, status) {
      let postData = {
        idList: ids,
        status: status
      }
      this.$axios.put(batchURL, postData).then(res => {
        if (res.status === 100) {
          this.$refs.list.searchData()
          this.$message.success('操作成功')
        }
      })
    },
    async batch (selected, status) {
      let isOk = await this.$confirm('确定执行该操作？')
      isOk && this.updateStatus(selected.ids, status)
    },
    async closeStatus (row) {
      let isOk = await this.$confirm('是否关闭该运营位？')
      isOk && this.updateStatus([row.id], 0)
    },
    create () {
      this.$router.push({
        name: 'operationPosForm'
      })
    },
    edit (row) {
      this.$router.push({
        name: 'operationPosForm',
        query: {
          id: row.id
        }
      })
    }
  }
}
</script>
