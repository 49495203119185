// 获取列表
const getListURL = `${API_CONFIG.butlerBaseURL}operationPosition`
// 导出列表
const exportListURL = `${API_CONFIG.shopBaseURL}card/estate/export`
// 获取组织标签列表
const getDropDownSearchTagURL = `${API_CONFIG.butlerBaseURL}orgTag/getDropDownSearchTag`
// 批量操作
const batchURL = `${API_CONFIG.butlerBaseURL}operationPosition/batchUpdate`

// 新增 编辑 查询详情
const createURL = `${API_CONFIG.butlerBaseURL}operationPosition`
// 上传
const uploadURL = `${API_CONFIG.uploadURL}?module=shopgoods`

// 获取项目列表
const getCommunityListURL = `${API_CONFIG.controlBaseURL}community/getCommunityList`
// 获取运营项目组
const getOperateRegionsURL = `${API_CONFIG.baseURL}serverCodewordAction!getOperateRegions.action`
// 获取阶段状态
const getProjectTypesURL = `${API_CONFIG.baseURL}serverCodewordAction!getProjectTypes.action`
// 项目类型
const getRoomTypesURL = `${API_CONFIG.baseURL}serverCodewordAction!getRoomTypes.action`

// 组织标签multiselect
// 获取列表
const commonOrgTagSearchURL = `${API_CONFIG.butlerBaseURL}orgTag/commonOrgTagSearch?isPartyTag=0`
// 获取上级标签列表
const getSuperiorTagByTagNameURL = `${API_CONFIG.butlerBaseURL}orgTag/getSuperiorTagByTagName`

// 预览地址
const mobilePreviewURL = `${API_CONFIG.origin}h5/community/operationPos/index.html#/?preview=1&time=${new Date().getTime()}`
// 检测园区是否可以跳转
const checkSkipCommunityURL = `${API_CONFIG.shopBaseURL}skipLifeShu/checkSkipCommunity`

// 查询全部的一级标签
const getAllFirstLabel = `${API_CONFIG.butlerBaseURL}user/orgTag/current/user/superiorTag`
export {
  getListURL,
  exportListURL,
  getDropDownSearchTagURL,
  batchURL,
  createURL,
  uploadURL,
  getCommunityListURL,
  getOperateRegionsURL,
  getProjectTypesURL,
  getRoomTypesURL,
  commonOrgTagSearchURL,
  getSuperiorTagByTagNameURL,
  mobilePreviewURL,
  checkSkipCommunityURL,
  getAllFirstLabel
}
