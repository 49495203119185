import {
  mapHelper,
  generateMapByOpts
} from 'common/utils'

// 状态
const status = [{
  text: '已关闭',
  value: 0
}, {
  text: '待审核',
  value: 2
}, {
  text: '已审核',
  value: 1
}, {
  text: '已退回',
  value: 3
}]

const {
  map: statusMap,
  setOps: setStatusOps
} = mapHelper.setMap(status)

// 组织置顶
const orgSticky = [{
  text: '置顶',
  value: 1
}, {
  text: '不置顶',
  value: 0
}]

const {
  map: orgStickyMap,
  setOps: setOrgStickyOps
} = mapHelper.setMap(orgSticky)
// 初始化运营位data
const patternData = {
  showType: 1,
  skipType: 1,
  skipUrl: '',
  picUrl: '',
  mainTitle: '',
  subTitle: '',
  skipData: ''
}

const initPatternList = (num) => {
  let list = []
  for (let i = 1; i <= num; i++) {
    list.push({
      pattern: i,
      ...patternData
    })
  }
  return list
}
// 跳转类型
const skipTypeOpt = [{
  label: '至功能页面',
  value: 1
}, {
  label: '至外部链接',
  value: 2
}]

const {
  map: skipTypeOptMap,
  setOps: setSkipTypeOptOps
} = mapHelper.setMap(skipTypeOpt)

// 展示形式
const showTypeOpt = [{
  label: '封面图',
  value: 1
}, {
  label: 'icon',
  value: 2
}]

const {
  map: showTypeOptMap,
  setOps: setShowTypeOptOps
} = mapHelper.setMap(showTypeOpt)

// 发布位置
const publishPosition = [{
    text: '首页运营位',
    label: '首页运营位',
    value: 1
}, {
    text: '服务运营位',
    label: '服务运营位',
    value: 2
}, {
    text: '预存运营位',
    label: '预存运营位',
    value: 3
}, {
    text: '充值运营位',
    label: '充值运营位',
    value: 4
}, {
    text: '钱包开通运营位',
    label: '钱包开通运营位',
    value: 5
}, {
    text: '开户成功运营位',
    label: '开户成功运营位',
    value: 6
}]

const {
  map: publishPositionMap,
  setOps: setPublishPositionOps
} = mapHelper.setMap(publishPosition)

// 项目状态
const communityStatus = [{
  text: '正常',
  value: 0
},
{
  text: '关闭',
  value: 1
}
]

const {
  map: communityStatusMap,
  setOps: setCommunityStatusOps
} = mapHelper.setMap(communityStatus)
// 区域类型
const regionTypeOps = [
  {
    text: '全部',
    value: undefined
  },
  {
    text: '公司',
    value: 0
  },
  // {
  //   text: '园区公司分公司',
  //   value: 1
  // },
  {
    text: '管理项目组',
    value: 2
  }
]

const regionTypeMap = generateMapByOpts(regionTypeOps)

// jump-go校验参数映射
const jumpGoCodeMap = {
  storeCode: 'storeIdList', // 门店
  productCode: 'productIdList', // 商品
  secKillCode: 'secKillIdList', // 秒杀
  discountTopicCode: 'discountTopicId', // 促销专题
  knowledgeInfoCode: 'knowledgeInfoIdList', // 资讯
  postsCode: 'tableIdList', // 帖子
  groupCode: 'groupIdList', // 社群
  socialActivityCode: 'socialActivityIdList', // 社区活动
  surveyCode: 'questionIdList', // 问卷调查
  selfRuleCode: 'selfRuleIdList', // 自治规约
  autonomyVoteCode: 'questionNaireIdList', // 自治表决
  workRoomCode: 'workRoomIdList' // 社区工作室
}
export {
  statusMap,
  setStatusOps,
  publishPositionMap,
  setPublishPositionOps,
  orgStickyMap,
  setOrgStickyOps,
  initPatternList,
  skipTypeOptMap,
  setSkipTypeOptOps,
  showTypeOptMap,
  setShowTypeOptOps,
  communityStatusMap,
  setCommunityStatusOps,
  regionTypeOps,
  regionTypeMap,
  jumpGoCodeMap
}
